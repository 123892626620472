exports.init = function() {
    const $ = require('../../../node_modules/jquery/dist/jquery.slim');
    window.$ = window.jQuery = $;

    const govukFrontend = require('govuk-frontend');
    govukFrontend.initAll();

    const cookies = require("./cookies");
    window.getCookie = cookies.getCookie;
    window.hideCookieBanner = cookies.hideCookieBanner;
    window.cookiesPage = cookies.cookiesPage;
    cookies.cookieBanner();

    const commonGaEvents = require('./commonGaEvents');
    window.gaEventValidationError = commonGaEvents.gaEventValidationError;
    window.gaEventBackLink = commonGaEvents.gaEventBackLink;
    window.gaEventExitPage = commonGaEvents.gaEventExitPage;
    window.gaEventOfflineFormDownload = commonGaEvents.gaEventOfflineFormDownload;
    window.gaEventOfflineFormDownloadVoluntary = commonGaEvents.gaEventOfflineFormDownloadVoluntary;
    window.gaEventContactLocalElectoralRegistrationOffice = commonGaEvents.gaEventContactLocalElectoralRegistrationOffice;
    window.gaEventErrorPage = commonGaEvents.gaEventErrorPage;
    window.gaEventSubmitButtonClick = commonGaEvents.gaEventSubmitButtonClick;

    window.setTimeoutWarning = require('./timeout-warning').setTimeoutWarning;

    window.commonPages = {
        manualAddress: require("./commonPages/manual-address").manualAddress,
        addressAnalytics: require('./commonPages/address').addressAnalytics,
        contact: require('./commonPages/contact').contact,
        nino: require('./commonPages/nino').nino,
        dateOfBirth: require('./commonPages/date-of-birth').dateOfBirth,
        privacy: require('./commonPages/privacy').privacy,
        uploadDocuments: require('./commonPages/upload-documents').uploadDocuments,
        uploadDocumentsPreSelected: require("./commonPages/upload-documents-pre-selected").uploadDocumentsPreSelected,
        nationalityAutocomplete: require('./commonPages/country-autocomplete').nationalityAutocomplete,
        countryOfResidenceAutocomplete: require('./commonPages/country-autocomplete').countryOfResidenceAutocomplete,
        nationalityButtons: require('./commonPages/nationality-buttons').nationalityButtons,
        submitForm: require('./components/submitForm'),
        duplicateApplication: require('./commonPages/duplicate-application').duplicateApplication,
        photoUpload: require('./commonPages/photo-upload').photoUpload,
        photoCheck: require('./commonPages/photo-check').photoCheck,
        confirmation: require('./commonPages/confirmation').confirmation,
        name: require('./commonPages/name').name
    };

    window.decodeHtml = function(html) {
        if ('DOMParser' in window) {
            const doc = new DOMParser().parseFromString(html, "text/html");
            return doc.documentElement.textContent;
        }
        return html;
    };
};
